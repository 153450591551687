import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './aws-exports'
import { BrowserRouter } from 'react-router-dom';

Amplify.configure({...config, cookieStorage: {
  domain: process.env.REACT_APP_AMPLIFY_COOKIES_DOMAIN,
  secure: true,
  path: '/',
  expires: 7,
}})

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  
      <React.StrictMode>
        <Authenticator.Provider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </Authenticator.Provider>
      </React.StrictMode>

);