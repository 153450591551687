import React, {useEffect,useState, useRef} from "react";
import annotationPlugin from 'chartjs-plugin-annotation';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    ArcElement,
    PolarAreaController,
  } from 'chart.js';
import { Chart} from 'react-chartjs-2';
import { GiFamilyHouse } from "react-icons/gi";

ChartJS.register(
    annotationPlugin,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale, 
    ArcElement,
    PolarAreaController,
    Filler,
    Title,
    Tooltip,
    Legend,
  );



export default function WindRose (props) {
    const {windRoseData, orderedData, bgColor, turbine} = props;
    const [windDirectionData, setWindDirectionData] = useState(false)
    const chartEl = useRef(null)
    const [legendRendered, setLegendRendered] = useState(false)
    const plugins = [
        // {
        //     id: 'center angle lines ',
        //     beforeDraw: (chart, args, options) => {

              
                // for (let i=0; i<(options._context.chart._metasets).length; i++) {

                //     let me = options._context.chart._metasets[i].controller._cachedMeta.controller
                //     const scale = me.chart.scales.r
                //     const chart = me.chart;
                //     const dataset = me.getDataset();
                //     console.log(me)
                //     const opts = chart.options;
                //     console.log(dataset)
                //     const xCenter=scale.xCenter
                //     const yCenter=scale.yCenter
                //     let dataSetStartAngle = scale.getIndexAngle(1)
                //       const defaultAngle = me.countVisibleElements();
                // }
               
          
        //        const {ctx} = chart;
           
        //  console.log(ctx)
        //       ctx.save();
            
        //       ctx.globalCompositeOperation = 'destination-over';
        //       ctx.fillStyle = options.color || '#99ffff';
        //       ctx.fillRect(0, 0, chart.width, chart.height);
        //       ctx.restore();
        //     }
        //   },

        {
         afterDatasetsDraw() {
           // hack to force re-render component in order to show legend
           if (!legendRendered) {
             setLegendRendered(true);
           }
         },
       },
   ]
    useEffect(() => {
        if (!orderedData) return
        let curTurbineLineCoord = [{x:0,y:0},{x:0,y:0}]
 
        let currentNacelle = 0
        if (turbine) {

          currentNacelle = parseInt(turbine['nacellePosition'])
         
          let oppositeNacelle = 0
          if (currentNacelle < 90) {
            const rad = ((90-currentNacelle)* Math.PI) / 180
           oppositeNacelle = Math.tan(rad)*10
           curTurbineLineCoord = [{ x:-10,y:(-oppositeNacelle+1)} , {x:10,y:(oppositeNacelle+1)}]
          
          } else if (currentNacelle >= 90 && currentNacelle < 180) {
            const rad = (-90+currentNacelle) * Math.PI/180
            oppositeNacelle = Math.tan(rad)*10
            curTurbineLineCoord = [{ x:-10,y:(oppositeNacelle+1)} , {x:10,y:(-oppositeNacelle+1)}]
          
          } else if (currentNacelle >= 180 && currentNacelle < 270) {
            const rad = (270-currentNacelle) * Math.PI/180
            oppositeNacelle = (Math.tan(rad))*10
            curTurbineLineCoord = [{ x:-10,y:(-oppositeNacelle+1)} , {x:10,y:(oppositeNacelle+1)}]
           
          } else if (currentNacelle >= 270 && currentNacelle < 360) {
            const rad = (currentNacelle-270) * Math.PI/180
            oppositeNacelle = (Math.tan(rad))*10
              curTurbineLineCoord = [{ x:-10,y:(oppositeNacelle+1)} , {x:10,y:(-oppositeNacelle+1)}]
             
          }
        }
        let currentWindDirection = orderedData.at(-1)[1012935240]['Wind direction']

          // proper arrow direction
           let arrowStart = false;
           let arrowEnd = false;
           
           let curWindArrowCoord = []
           let opposite = 0
           if (currentWindDirection < 90) {
              const rad = ((90-currentWindDirection)* Math.PI) / 180
               opposite = Math.tan(rad)*5
               curWindArrowCoord = [{ x:-5,y:-opposite} , {x:5,y:opposite}]
               arrowStart = true
           } else if (currentWindDirection >= 90 && currentWindDirection < 180) {
               const rad = (-90+currentWindDirection) * Math.PI/180
               opposite = Math.tan(rad)*5
               curWindArrowCoord = [{ x:-5,y:opposite} , {x:5,y:-opposite}]
               arrowStart = true
           } else if (currentWindDirection >= 180 && currentWindDirection < 270) {
                const rad = (270-currentWindDirection) * Math.PI/180
               opposite = (Math.tan(rad))*5
               curWindArrowCoord = [{ x:-5,y:-opposite} , {x:5,y:opposite}]
              arrowEnd = true
           } else if (currentWindDirection >= 270 && currentWindDirection < 360) {
              const rad = (currentWindDirection-270) * Math.PI/180
               opposite = (Math.tan(rad))*5
               curWindArrowCoord = [{ x:-5,y:opposite} , {x:5,y:-opposite}]
               arrowEnd = true
           }
           const optionsWindDirection = {
            aspectRatio: 1,
            color: 'whitesmoke',
            padding: 0,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function(data) {
                            let rawData = data.raw
                            const datasetIndex = data.datasetIndex
                            const dataIndex= data.dataIndex
                            const datasets = data.chart._sortedMetasets
                            for (let i=datasetIndex-1; i>=0; i--) {
                                if (datasets[i]._dataset.data[dataIndex] > 0) {
                                    rawData -= datasets[i]._dataset.data[dataIndex]
                                    break;
                                }
                            
                            }
                            return data.dataset.label + ': ' + (rawData*100).toFixed(2) +'%'
                        }
                    }
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            arrowHeads: {
                                start: {
                                    display: arrowStart,
                                },
                                end: {
                                  display: arrowEnd
                                },
                            },
                            backgroundColor: 'lightGreen',
                            borderColor: 'lightGreen',
                            xMin: curWindArrowCoord[0].x,
                            xMax: curWindArrowCoord[1].x,
                            yMin: curWindArrowCoord[0].y,
                            yMax: curWindArrowCoord[1].y,
                            borderWidth: 2
                          },
                          line2: {
                            type: 'line',
                            display: turbine,
                            backgroundColor: 'gray',
                            borderColor: 'gray',
                            xMin: curTurbineLineCoord[0].x,
                            xMax: curTurbineLineCoord[1].x,
                            yMin: curTurbineLineCoord[0].y,
                            yMax: curTurbineLineCoord[1].y,
                            borderWidth: 5
                          },
                          point1: {
                            type: 'point',
                            display: turbine,
                            xValue: 0,
                            yValue: 0,
                            backgroundColor: 'gray',
                            radius: 5,
                          }
                        } 
                  }
            },
            parsing: {
                key: 'value'
              },
            scales: {
                x: {
                    type: 'linear',
                    position: 'center',
                    min: -15,
                    max: 15,
                    color: 'whitesmoke',
                    display: false,
                    offset: true,
                    ticks: {
                        display: true,
                        stepSize: 5,
                    },
                    grid: {
                      color: 'whitesmoke',
                        display: true,
                    },
                    pointLabels: {
                        display: false,
                        font: {
                          size: 12

                        },
                        color: 'gray',
                      },
                    offset: true
                },
                y: {
                    type: 'linear',
                    position: 'center',
                    max: 15,
                    min: -15,
                    offset: true,
                    color: 'whitesmoke',
                    display: false,
                    ticks: {
                        display: true,
                        stepSize: 5
                    },
                    pointLabels: {
                        display: true,
                        font: {
                          size: 12
                        },
                        color: 'whitesmoke',
                      },
                    grid: {
                      color: 'whitesmoke',
                        display: false,
                    },
                },
                r: {
                    type: 'radialLinear',
                    startAngle: -15,
                    pointLabels: {
                        display: true,
                        centerPointLabels: true,
                        font: {
                          size: 12
                        },
                        // color: bgColor,
                      },
                      grid: {
                        display: true,
                        color: 'gray',
                      },
                   ticks: {
                       display: true,// Hides the labels in the middel (numbers)
                       beginAtZero: true, 
                       showLabelBackdrop: false,
                     callback: function(value, index, ticks) {
                        return value*100 + '%';
                    },
                    maxTicks: 2,
                    color: 'whitesmoke'
                   },
                   angleLines: {
                    display: false,
                   }
                  
               },
           },
           elements: {
            arc: {
                circular: true,
                startAngle: 30,
            }
          }
        }   

          const windDirectionData = {
            
            labels: ['N', 'NNE', 'ENE', 'E', 'ESE', 'SSE', 'S', 'SSW', 'WSW', 'W', 'WNW', 'NNW'],
            datasets: [
              {
                label: '<3 (m/s)',
                type: 'polarArea',
                data: windRoseData.calm,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    
                ],
                borderWidth: 2,
              },
              {
                label: '3-6 (m/s)',
                type: 'polarArea',
                data: windRoseData.mild,
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    
                ],
                borderWidth: 2,
              },
              {
                label: '6-11 (m/s)',
                type: 'polarArea',
                data: windRoseData.moderate,
                backgroundColor: [
                    'rgba(255, 206, 86, 0.6)',
                ],
                borderWidth: 2,
              },
              {
                label: '11-16 (m/s)',
                type: 'polarArea',
                data: windRoseData.strong,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                ],
                borderWidth: 2,
              },
              {
                label: '>16 (m/s)',
                type: 'polarArea',
                data: windRoseData.high,
                backgroundColor: [
                    'rgba(153, 102, 255, 0.6)',
                   
                ],
                borderWidth: 2,
              },
            ],
          };
          setWindDirectionData({options: optionsWindDirection, data: windDirectionData})
    }, [windRoseData, turbine])

  
    return (

        <div style={{height:'100%', display: 'flex',  width: '100%', alignItems: 'center', justifyContent:'space-evenly'}}>
                {legendRendered && (
            <div
            style={{
                alignContent: 'center',
            height: '240px',
            width: '115px',
            
            }}
            >
            <div style={{display: 'flex',fontSize:'.8em', justifyContent: 'space-evenly', textAlign:'center', alignItems: 'center', height: '20%',margin: '0', padding: '0' }}>
                    <p>
                   Wind Speed
                </p>
            </div>
          <ul style={{listStyle: 'none', margin: '0', padding: '0', height: '45%', width:'100%'}}>
           
            
            {chartEl.current.legend.legendItems.map((tick, index, array) => {
                
              const isHidden = tick.hidden;
              const bgColor =
                typeof tick.fillStyle === 'string'
                  ? tick.fillStyle // lines
                  : 'linear-gradient(to bottom, #13B080, rgba(23,172,126,0.4))' // canvas gradient

              return (
                <li 
                style={{padding:'0', display: 'flex', justifyContent: 'space-evenly', alignContent:'center', alignItems: 'center', height: `${100/(array.length)}%` }}

                  key={tick.datasetIndex}
                >
                  <div
                    style={{ height: '30%', width: '15%', border: '1px solid white', display: isHidden ? 'hide' : 'show', backgroundColor: bgColor }}
                  />
                  <p
                    style={{textAlign: 'center', fontSize: '.6em', width: '70%',  display: isHidden ? 'hide' : 'show'}}
                  >
                    {tick.text}
                  </p>
             
                </li>
               
              );
            }
            )
            }

          </ul>
        </div>                          
      )}
            <div style={{width:'220px', height:'220px', }}>
          {windDirectionData &&(<Chart redraw={true} ref={chartEl} plugins={plugins} options={windDirectionData.options} data={windDirectionData.data}></Chart>)}
          </div>
        </div>

    )


}