import React, {useState, useEffect} from "react";
import Gauge from "../Gauge";
import WindRose from "../WindRose";
import {MdOutlineArrowDropDownCircle} from 'react-icons/md'
import {
    Chart as ChartJS,
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    ArcElement,
  } from 'chart.js';
import { Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns'
import { enUS} from 'date-fns/locale'
import { Authenticator} from '@aws-amplify/ui-react';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    RadialLinearScale, ArcElement,
    Filler,
    Title,
    Tooltip,
    Legend,
  );



const powerVsWindOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Power vs Wind Speed',
      },
    },
    scales: {
        x: 
            {
                type: 'time',
                adapters: { 
                    date: {
                      locale: enUS, 
                    },
                  }, 
                grid: {
                    color: 'gray',
                },
            
            }
        ,
        y: 
            {
                grid: {
                    color: 'gray'
                }
            }
        ,
       
    },
  };



const turbineAvailabilityOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
          text: 'Turbine Availability',
        },
      },
      scales: {
          x: 
              {
                  type: 'time',
                  adapters: { 
                      date: {
                        locale: enUS, 
                      },
                    }, 
                  grid: {
                      color: 'gray',
                  },
              
              }
          ,
          y: 
              {
                min: 0,
                max: 6,
                  grid: {
                      color: 'gray'
                  },
                  ticks: {
                    stepSize: 1
                  }
              }
          , 
      },
};

const powerLossOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Power Loss',
    },
  },
  scales: {
      x: 
          {
              type: 'time',
              adapters: { 
                  date: {
                    locale: enUS, 
                  },
                }, 
              grid: {
                  color: 'gray',
              },
          
          }
      ,
      y:  {
  
              grid: {
                  color: 'gray'
              },
          }
      , 
  },
};


export function WindDash (props) {
    const {orderedData, windRoseData, user} = props;
    const [loaded, setLoaded] = useState(false)
    const [powerVsWind, setPowerVsWind] = useState(false);
    const [turbineAvailability, setTurbineAvailability] = useState(false);
    const [powerLoss, setPowerLoss] = useState(false);
   
    useEffect(() => {
        if (!orderedData) return
  
        setPowerVsWind({
            datasets: [
            {
                label: 'Power',
                data: orderedData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                parsing: {
                    xAxisKey: 'time',
                    yAxisKey: '1012935240.Actual power of the turbines connected'
                  },
              },
              {
                label: 'Wind Speed',
                data: orderedData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                parsing: {
                    xAxisKey: 'time',
                    yAxisKey: '1012935240.Wind speed'
                  },
              },
            ],
          }) 
           
          setTurbineAvailability(
            {
              datasets: [
                    {
                        label: 'Turbine Availability',
                        data: orderedData,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        parsing: {
                            xAxisKey: 'time',
                            yAxisKey: '1012935240.Number of available turbines'
                          }
                      }
                  ]
              }
            )
 
            setPowerLoss(
              {
                datasets: [
                      {
                          label: 'Calculated Power loss of wind farm',
                          data: orderedData,
                          borderColor: 'rgb(255, 99, 132)',
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                          parsing: {
                              xAxisKey: 'time',
                              yAxisKey: '1012935240.Calculated Power loss of the windfarm'
                            }
                        }
                    ]
                }
              )

        setMostRecentData({
                Power: orderedData.at(-1)[1012935240]['Actual power of the turbines connected']*1000,
                WindSpeed: orderedData.at(-1)[1012935240]['Wind speed'],
           })
          setLoaded(true)
    }, [orderedData])

    const [mostRecentData, setMostRecentData] = useState(false)
   
    useEffect(()=> {
       
    },[orderedData])

    return (
      <>
      {user ?  (
        <div style={{width: '100%', height: '88vh'}}>
            <div style={{margin: 0, padding: 0,  backgroundColor: '#212529', border: '1px solid gray', boxShadow:'inset 0 -3em 3em #30363C', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '6vh', alignItems: 'center'}}>
           <div style={{width: '30%', textAlign: 'center'}}>
           Your Wind Farm's Name
           </div>
            <div style={{width: '35%'}}></div>
            <button disabled={true} style={{ fontSize: '.8em', width: '35%',color: 'whitesmoke', height: '100%',textAlign: 'center', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #23262A, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', backgroundColor: '#495057',boxRadius: '1em'}}>
               
                
                   Go To Turbine View
                
          
               
                </button>
         

          </div>
          <div style={{height: '4vh', width: '100%', display: 'flex'}}>
             <button disabled={true}
          style={{display: 'flex', width: '20%', justifyContent: 'space-evenly', alignItems: 'center', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #23262A, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', backgroundColor: '#495057', height: '100%', boxRadius: '1em', fontSize: '.8em', color: 'whitesmoke'}}>
          Power Vs Wind 
            <MdOutlineArrowDropDownCircle size={'3vh'}/>
          </button>
             <form style={{display: 'flex', fontSize: '.8em', alignItems: 'center', justifyContent: 'space-evenly', width:'80%', height: '100%'}}>
              <div>
              <label>24hr</label><input checked={true} type="radio" aria-selected id="24hr" name="time_frame" value="24hr" ></input>
              </div>
              <div>
              <label>1Week</label><input disabled={true} type="radio" id="1Week" name="time_frame" value="1Week" ></input>
              </div>
              <div>
              <label>1Month</label><input disabled={true} type="radio" id="1Month" name="time_frame" value="1Month" ></input>
              </div>
              <div>
              <label>6Month</label><input disabled={true} type="radio" id="6Month" name="time_frame" value="6Month"></input>
              </div>
              <div>
              <label>1Year</label><input disabled={true} type="radio" id="1Year" name="time_frame" value="1Year" ></input>
              </div>
              <div>
              <label>3Year</label><input disabled={true}  type="radio" id="3Year" name="time_frame" value="3Year"></input>
              </div>
             </form>
            </div>
            <div style={{width: '100%',height: '34vh',  display: 'flex'}}>
           
                <div style={{ backgroundColor: '#23262A',border:'3px solid gray', height: '100%', width: '100%'}}>
                {loaded &&  (<Line  options={powerVsWindOptions} data={powerVsWind} />)}
                </div> 
                      
            </div>
            <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-evenly', flexWrap: 'wrap', height: '40vh', marginTop: '2vh', width:'100%'}}>

            <div style={{ display: 'flex', flexDirection: 'column', boxShadow:'inset 0 -3em 3em #30363C',border:'3px solid gray', textAlign: 'center', height: '100%', width: '150px'}}>
               <p>    
                            Installed Park Capacity<br/>
                                9.72 MW
                 </p>
                 <p>    
                Number of Available Turbines<br/>
                      {loaded && (<>{orderedData.at(-1)[1012935240]['Number of available turbines']} </>)}
                </p>
              </div>

              <div style={{ display: 'flex', flexDirection:'column', marginBottom: '1vh', border:'1px solid gray', boxShadow:'inset 0 -3em 3em #30363C', height: '100%', width: '300px'}}>

                  <div style={{height: '10%',display: 'flex', width: '100%'}} >
                      <div style={{boxShadow:'inset 0 -3em 3em #30363C', fontSize: '.8em', paddingTop: '1%', width: '190px', textAlign:'center', height: '100%'}}>
                          24hr Energy Production
                      </div>
                      <div  style={{fontSize: '.8em', paddingTop: '1%',width: '110px',textAlign:'center',  height: '100%'}}>
                      {loaded && (<> {orderedData.reduce(
                                    (accumulator, currentValue) =>  accumulator + parseFloat(currentValue[1012935240]['Actual power of the turbines connected'])/(orderedData.length/24), 0).toFixed(2)} 
                                    MWhr </>)}
                      </div>
                  </div>
            <div style={{display: 'flex', height: '90%', width: '100%'}}>
            <Gauge style={{height: '100%', padding: '2%', width: '50%', border:'1px solid gray'}} title={'Power (kW)'} unitType={'kW'} limitMetric={2000} maxMetric={9720} datum={mostRecentData.Power} percent={true} metric={true} metricFirst={true}></Gauge>
                        
            <Gauge style={{height: '100%', padding: '2%', width: '50%', border: '1px solid gray'}} title={'Wind Speed (m/s)'} unitType={'m/s'} limitMetric={20} maxMetric={20} datum={mostRecentData.WindSpeed} percent={false} metric={true} metricFrist={false}></Gauge>
                       </div> 
              </div>
                        
                         
                  
              <div style={{border: '1px solid gray', marginBottom: '1vh', width: '400px', boxShadow:'inset 0 -3em 3em #30363C', height:'100%'}}>

                    <div style={{width: '100%',  textAlign: 'center', border: '1px solid gray', boxShadow:'inset 0 -3em 3em #30363C', margin: 0, height: '8%', font: '8em', padding: 0}}>24 hour wind Report</div>
                    <div style={{height: '12%', width: '100%', textAlign: 'center', display: 'flex', fontSize: '.8em', padding: 0}}>
                                
                    {loaded && (
                    <div style={{textAlign: 'center', height: '100%', display: 'flex', alignItems: 'center', width: '100%'}}>
                    <p style={{width: '60%', height: '100%', fontSize: '1.8vh', backgroundColor: '#212529', border: '1px solid gray', padding: 8, margin: 0}}>
                        Current Wind Direction <span style={{color: "lightGreen"}}>({'\u2798'}) </span>
                        </p>
                        <p style={{width: '40%',height: '100%',  padding: 8, fontSize: '1.8vh',  border: '1px solid gray', backgroundColor: "#050506", margin: 0}}>
                        {parseFloat(orderedData.at(-1)[1012935240]['Wind direction']).toFixed(2)} {'\u00B0'}
                            </p>
                    </div>
                    )}

                        </div>
                        <div style={{height: '80%'}}>
                    {windRoseData && (<WindRose turbine={false} bgColor={'#23262A'} windRoseData={windRoseData} orderedData={orderedData} />) }
                    </div>
                    </div>
                         
                </div>
        </div>
        ) : (<div  style={{width: '30em', margin: 'auto', backgroundColor: '#212529', marginTop: '5vh', borderRadius: '10px'}}>
        <h4 style={{paddingTop: '10px'}}>Create a VayuAI Data Account to View Our Sample Wind Dashboard</h4>
        
        <Authenticator variation={'secondary'}></Authenticator>
        
    </div>) }
        </>
    )
}