import React, { useEffect, useState } from 'react'
import {  useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { NavBar } from './components/NavBar';
import { MapView } from './components/MapView';
import { Login } from './components/Login';
import { Routes, Route } from 'react-router-dom'
import { WindDash } from './components/WindDash';
let orderedData = require('./seedData/orderedData.json')
let windRoseData = require('./seedData/windRoseData.json')
const vayuOppsApi = "vayuOpps";
const usTurbineApi = "USTurbineData";


function App() {
  const [top200, setTop200] = useState([]);
  const [poas, setPoas] = useState([]);
  const [capAssessments, setCapAssessments] = useState([]);
  const [turbineSplit1, setTurbineSplit1] = useState(false);
  const [turbineSplit2, setTurbineSplit2] = useState(false);
  const [turbineSplit3, setTurbineSplit3] = useState(false);
  const [turbineSplit4, setTurbineSplit4] = useState(false);
  const [turbineSplit5, setTurbineSplit5] = useState(false);
  const [usTurbineData, setUsTurbineData] = useState(false);
  const [userGroup, setUserGroup] = useState(false)
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user
  ]);

  // fetch CHSB Data
  useEffect(() =>{
    if (!user) return
    if (!user.signInUserSession) return 
    if (!user.signInUserSession.accessToken.payload['cognito:groups']) return
    const group =  user.signInUserSession.accessToken.payload['cognito:groups'][0]
    setUserGroup(group)
  }, [user])


  // fetch Vayu Opportunities
  useEffect(() =>{
    if (!user) return
    let fetched = false
    API.get(vayuOppsApi, '/top200')
      .then(response => {
        if (!fetched) {
          setTop200(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }
  }, [user])

  useEffect(() =>{
    if (!user) return
    let fetched = false
    API.get(vayuOppsApi, '/poas2022')
      .then(response => {
        if (!fetched) {
          setPoas(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }
  }, [user])

  useEffect(() =>{
    if (!user) return
    let fetched = false
    API.get(vayuOppsApi, '/capAssessments')
      .then(response => {
        if (!fetched) {
          setCapAssessments(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }

  }, [user])

  // fetch US Turbine Data

  useEffect(() =>{
   
    let fetched = false
    API.get(usTurbineApi, '/getTurbineSplit1')
      .then(response => {
        if (!fetched) {
          setTurbineSplit1(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }

  }, [])
  useEffect(() =>{
    
    let fetched = false
    API.get(usTurbineApi, '/getTurbineSplit2')
      .then(response => {
        if (!fetched) {
          setTurbineSplit2(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }

  }, [])
  useEffect(() =>{
   
    let fetched = false
    API.get(usTurbineApi, '/getTurbineSplit3')
      .then(response => {
        if (!fetched) {
          setTurbineSplit3(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }

  }, [])

  useEffect(() =>{
  
    let fetched = false
    API.get(usTurbineApi, '/getTurbineSplit4')
      .then(response => {
        if (!fetched) {
          setTurbineSplit4(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }

  }, [])

  useEffect(() =>{

    let fetched = false
    API.get(usTurbineApi, '/getTurbineSplit5')
      .then(response => {
        if (!fetched) {
          setTurbineSplit5(response)
        }
      })
      .catch(error => {
        console.log(error)
      })

      return () => {
        fetched = true;
      }

  }, [])

  // Combine US Turbine Data
  useEffect(()=> {
    if (turbineSplit1 && turbineSplit2 && turbineSplit3 && turbineSplit4 && turbineSplit5) {
      setUsTurbineData([...turbineSplit1, ...turbineSplit2, ...turbineSplit3, ...turbineSplit4, ...turbineSplit5])

    }

  }, [turbineSplit1, turbineSplit2, turbineSplit3, turbineSplit4, turbineSplit5])

  return (
      <>
      <NavBar route={route} user={user} userGroup={userGroup} signOut={signOut}/>
      <Routes>
        <Route path="/" element={<MapView user={user} capAssessments={capAssessments} poas={poas} top200={top200} usTurbineData={usTurbineData}/>}/>
        <Route path="/samplewinddashboard" element={<WindDash orderedData={orderedData} windRoseData={windRoseData} user={user}/>}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="*"  element={<MapView user={user} capAssessments={capAssessments} poas={poas} top200={top200} usTurbineData={usTurbineData}/>} />
      </Routes>
      </>
  );
}

export default App;
