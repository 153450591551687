import React from "react";
import {useNavigate, useLocation, Link } from 'react-router-dom';
import styles from './NavBar.module.css';

export function NavBar(props) {
   const {user, userGroup, signOut} = props

  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }
  return (
  <div className={styles.Navbar}>
    <nav>
    <Link style={{display: 'flex', alignItems:'center', textDecoration: 'none', fontSize: '1.8em'}} to="/" >
    <img src="vayu-logo.png" alt="Vayu AI Brand Logo" width="150" height="70"/>

    </Link>
    </nav>
   <nav className={"Navbar__Items Navbar__Items--Right"}>
    <Link className={styles.NavItem} style={{color:'whitesmoke', textDecoration: 'none'}} to="/">
      Wind Opportunity Viewer
    </Link>
    {userGroup &&  <a className={styles.NavItem} style={{color:'whitesmoke', textDecoration: 'none'}} href={`https://${userGroup}.vayuaidata.com`}>
      {userGroup.toUpperCase()} Dashboard
    </a>
    }
    <Link className={styles.NavItem} style={{color:'whitesmoke', textDecoration: 'none'}} to="/samplewinddashboard">
      Sample Wind Dashboard
    </Link>
    {user ? (
    <button onClick={()=> {logOut()}} className="Navbar__Item Navbar__Button">
      Sign Out
    </button>
   ) : (
    <Link className={styles.NavItem} style={{color:'whitesmoke', border: '1px solid gray', padding: 2, borderRadius: '5px', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #495057, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', backgroundColor: '#23262A', textDecoration: 'none'}} to="/login">
      Sign In/Create Account
    </Link>
    )}
  </nav> 
</div>
  )
}