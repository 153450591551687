import React, { useEffect, useState } from "react";

function Metric(props) {
    const {limitMetric} = props
    return (
    <div style={{width: 'max-content', display: 'flex', alignContent: 'center', height: '95%', flexDirection: 'column'}}>
                            
                            
                            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
                                    {limitMetric}
                            </div>
                            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
                                    {limitMetric*.75}
                            </div>
                            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
                                {limitMetric*.50}
                            </div>
                            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
                                {limitMetric*.25}
                            </div>
                    </div>
    )
}

function Percent(props) {
    const {maxMetric, limitMetric} = props
    return ( 
        <div style={{width: 'max-content', display: 'flex', alignContent: 'center', height: '95%', flexDirection: 'column'}}>
            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
            {Math.floor(limitMetric/maxMetric*100)}%
            </div>
            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
            {Math.floor(limitMetric*.75/maxMetric*100)}%
            </div>
            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
            {Math.floor(limitMetric*.50/maxMetric*100)}%
            </div>
            <div style={{fontSize:'.2em',display: 'flex', height:'25%', flexDirection: 'row',width: '100%'}}>
            {Math.floor(limitMetric*.25/maxMetric*100)}%
            </div>
        </div>
    )   
}

export default function Gauge (props) {
    const {title, style, unitType, limitMetric, maxMetric, datum, percent, metric, metricFirst} = props

    return (
        <div style={style}>
            <div style={{height: '12%', fontSize:'.8em', textAlign: 'center'}}>{title}</div>
            
            <div style={{margin: '4% 10% 4% 10%', height: '66%', display: 'flex', width: '80%', flexDirection: `row`, justifyContent:'space-around'}}>
                {(metric && metricFirst) && (
                 <Metric limitMetric={limitMetric}/>)
             }
             {(percent && !metricFirst) && (
                 <Percent maxMetric={maxMetric} limitMetric={limitMetric}/>)
             }
                     <div style={{width: '5%', display: 'flex', alignContent: 'center', height: '95%', alignSelf:'flex-end',flexDirection: 'column', borderRight:'1px solid gray'}}>
                            <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                            </div>
                            <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                            </div>
                            <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                            </div>
                            <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                            </div>
                    </div>
                <div style={{display: 'flex', flexDirection: 'column', height: '95%', alignSelf: 'flex-end', justifyContent: 'flex-end', width: '10%', backgroundImage: "linear-gradient(#a6ecc7, #ecf0a2, #e7bf88, #d28d8b)"}}>
                    <div style={{height: `${!datum ? 0 : datum/limitMetric*100}%`, width: '100%', backgroundColor: 'red', alignSelf: 'bottom'}}>
                    </div>
                </div>
                    <div style={{width: '5%', display: 'flex', alignContent: 'center', height: '95%', alignSelf:'flex-end',flexDirection: 'column', borderLeft:'1px solid gray'}}>
                        <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                        </div>
                        <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                        </div>
                        <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                        </div>
                        <div style={{display: 'flex', height:'25%', flexDirection: 'row',width: '100%', borderTop:'1px solid gray'}}>
                        </div>
                    </div>
                    {(metric && !metricFirst) && (
                 <Metric limitMetric={limitMetric}/>)
             }
             {(percent && metricFirst) && (
                 <Percent maxMetric={maxMetric} limitMetric={limitMetric}/>)
             }
            </div>
            <div style={{height: '14%', fontSize:'.8em', textAlign: 'center'}}>
                {parseFloat(datum).toFixed(1)} {unitType}
            </div>
        </div>
    )
}