import React, {useState, useEffect, useRef} from 'react';
import CustomMenu from "../CustomMenu";

export function Dropdown (props) {
     const {setInRegionOrSearchFarm, setTop200Benefits, projectNames, setSelectedFarm, children, style, className, 'aria-labelledby': labeledBy }= props
      const [show, setShow] = useState(false);
      let ref =useRef()
        useEffect(() => {
          const handleClickOutside = (event) => {
            
            if (!ref?.current?.contains(event.target)) {
              setShow(false);
            } else {
                setShow(true)
            }
          };
          document.addEventListener("mousedown", handleClickOutside);
        }, [ref]);
      
      return (

          <CustomMenu className={className} show={show} style={style} ref={ref} >   
         
        {projectNames.map((projectName)=> (<button style={{width: '100%'}} onClick={()=> {
            setTop200Benefits(false)
            setSelectedFarm(projectName)
            setInRegionOrSearchFarm(projectName)
            setShow(false);
        }} key={`${projectName}`}>{projectName}</button>))}
    
        </CustomMenu>
        );
}