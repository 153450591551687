import styles from "./CustomMenu.module.css"
import React, {useState, useEffect } from 'react';

const CustomMenu = React.forwardRef(
    ({ children, style, show, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
      return (
        <div
        style={style}
          className={className}
          aria-labelledby={labeledBy}
          ref={ref}
        >
          <form ><input placeholder="Search By Wind Farm Name..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{backgroundColor: 'rgba(19, 22, 24, 0.8)', width: '100%', height: '3em', boxRadius: '1em', fontSize: '.8em', color: 'whitesmoke'}}></input>
             </form>
             {show && ( <ul className="list-unstyled" style={{maxHeight: '60vh', overflowY: 'scroll', width: '100%', padding: 0, margin: 0}}>
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
            )}
          </ul>)
          }
         
       
        </div>
      );
},
)

export default CustomMenu;