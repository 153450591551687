import React, {useRef, useState, useEffect} from "react"
import { Dropdown } from "../Dropdown";
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';


import { useNavigate, useLocation } from 'react-router';
// TO MAKE THE MAP APPEAR YOU MUST
// ADD YOUR ACCESS TOKEN FROM
// https://account.mapbox.com
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


export function MapView(props) {
  const {usTurbineData, top200, capAssessments, user, poas} = props
  const [selectedFarm, setSelectedFarm] = useState(false)
  const [top200Benefits, setTop200Benefits] = useState(false)
  // state var to store Array of Farm Project Names 
  let [projectNames, setProjectNames] = useState([])
  let [turbinesInArea, setTurbinesInArea] = useState([])
  let [selectedFarmDetails, setSelectedFarmDetails] = useState(false)
  let [projectsInArea, setProjectsInArea] = useState([])
  let [selectedTurbines, setSelectedTurbines] = useState(false)
  let [loaded,setLoaded] = useState(false)
  let [inRegionOrSearchFarm, setInRegionOrSearchFarm] = useState(false)
  let [vayuCapAssess, setVayuCapAssess] = useState(false)
  let [vayuPoas, setVayuPoas] = useState(false)
  // effect hook to setProjectnamesarray and usTurbine Data

  useEffect(() => {
    if (usTurbineData) {
      let projectNamesSet = new Set() 
      let projectNamesArr = []
      for (let i=0; i<usTurbineData.length; i++) {
          if (!projectNamesSet.has(usTurbineData[i].p_name)) {
              projectNamesSet.add(usTurbineData[i].p_name)
              projectNamesArr.push(usTurbineData[i].p_name)
          }
      }
    
      setProjectNames(projectNamesArr)
     
    }
  }, [usTurbineData])

    // features for turbines with capacity ratings

    


  // Mapbox gl js boilerplate
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-98.438);
    const [lat, setLat] = useState(36);
    const [zoom, setZoom] = useState(3);


    useEffect(() => { 
      map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/alavezzo/cl9cipvfc000715tkllxwwezm',
      center: [lng, lat],
      minZoom: 3,
      zoom: zoom,
      });
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
    
        });
        map.current.on('mouseenter', 'usturbinesgeojson-5pyuhk', (event) => {
          map.current.getCanvas().style.cursor = 'pointer';
        })
        map.current.on('click', 'usturbinesgeojson-5pyuhk', (event) => {
          
          new mapboxgl.Popup()
          .setLngLat(event.lngLat)
          .setHTML(`<div class='Popup'> 
          Turbine ID: ${event.features[0].properties.case_id}<br/>
           Wind Farm: ${event.features[0].properties.p_name} <br/>
            Year Online: ${event.features[0].properties.p_year}<br/>
                 Rated Capacity:  ${event.features[0].properties.t_cap/1000} MW<br/>
                  Total Turbines: ${event.features[0].properties.p_tnum}<br/>
                 Hub Height: ${event.features[0].properties.t_hh} M<br/>
                 Total Height: ${event.features[0].properties.t_ttlh} M<br/>
                Rotor Diameter: ${event.features[0].properties.t_rd} M<br/>
                Model: ${event.features[0].properties.t_model}<br/>
                Manufacturer: ${event.features[0].properties.t_manu}<br/>
                Eia ID: ${event.features[0].properties.eia_id}<br/>
              State: ${event.features[0].properties.t_state}<br/>
               County: ${event.features[0].properties.t_county}<br/>
                Long/Lat: ${event.features[0].properties.xlong}X${event.features[0].properties.ylat}<br/>
          </div>`)
          .addTo(map.current);
          });
        map.current.on('mouseleave', 'usturbinesgeojson-5pyuhk', (event) => {
          map.current.getCanvas().style.cursor = 'grab';
        })
        map.current.on('load', () => {
          setLoaded(true)
        } )
       
        
      return () => {
        map.current.remove()
   
      }
      }, []);
    const callSetProjectsInArea =  () => {
     
      let turbineArray = []
      let projectNamesArr = []
      let projectNamesSet = new Set() 
      let turbineLayer = map.current.queryRenderedFeatures({layers: ['usturbinesgeojson-5pyuhk']})
      for (let i=0; i<turbineLayer.length; i++) {
        turbineArray.push(turbineLayer[i].properties)
        if (!projectNamesSet.has(turbineLayer[i].properties.p_name)) {
            projectNamesSet.add(turbineLayer[i].properties.p_name)
            projectNamesArr.push(turbineLayer[i].properties)
        }
      }
      setTurbinesInArea(turbineArray)
      setProjectsInArea(projectNamesArr)
    }
    useEffect(() => {
       if (!map.current) return
      
       if (zoom>6) {
        callSetProjectsInArea()
       }
      }, [zoom,lng, lat])


  useEffect(() =>{
    if (loaded) {
      let farm = []
      if (selectedFarm.associated_names) {
        farm = selectedFarm.associated_names
      } else {
        farm.push(selectedFarm)
      }
      map.current.setPaintProperty('selectedProject', 'circle-opacity', [
        "match",
        ["get", "p_name"],
        farm,
        0.4,
        0
      ])

    }

  }, [selectedFarm])

  useEffect(() => {
    let farm = []
    if (selectedFarm.associated_names) {
      if (typeof selectedFarm.associated_names !== 'string') {
        farm = selectedFarm.associated_names
      } else {
        farm.push(selectedFarm.associated_names)
      }
  
    } else {
      farm.push(selectedFarm)
    }
    let selectedTurbines= []
    let selectedFarmsDetails = []
    for (let i=0; i<farm.length; i++ ) {
      let manufacturers= new Set()
      let models = new Set()
      let farmObj= {
        name: farm[i],
        capacity:0, 
        totalTurbines: 0,
        yearOnline: 0,
        models: [],
        manufacturers: []
      }
      for (let j=0; j<usTurbineData.length; j++) {
        if (usTurbineData[j].p_name === farm[i]) {
          selectedTurbines.push(usTurbineData[j])
          farmObj.capacity = farmObj.capacity+(usTurbineData[j].t_cap/1000)
          farmObj.totalTurbines++
          farmObj.yearOnline = usTurbineData[j].p_year
          manufacturers.add(usTurbineData[j].t_manu)
          models.add(usTurbineData[j].t_model)
        }
      }

      farmObj.models = Array.from(models)
      farmObj.manufacturers = Array.from(manufacturers)
      selectedFarmsDetails.push(farmObj)
    }
 
    if (selectedTurbines.length) {
      map.current.flyTo({center: [selectedTurbines[0].xlong,selectedTurbines[0].ylat], zoom: 10, speed: 1.5}).on('idle', callSetProjectsInArea)
      setZoom(10)
      setLat(selectedTurbines[0].ylat)
      setLng(selectedTurbines[0].xlong)
      setSelectedTurbines(selectedTurbines)
      setSelectedFarmDetails([...selectedFarmsDetails])
    } 

  }, [selectedFarm, usTurbineData])
  
  useEffect(()=> {
    if (!inRegionOrSearchFarm || !capAssessments) return
 
    let capAssess = []
    let poas2022 = []
    for (let i=0; i<capAssessments.length; i++) {
      if (typeof capAssessments[i].associated_names === "string"){
          if (inRegionOrSearchFarm === capAssessments[i].associated_names) {
              capAssess.push(capAssessments[i])
          }
      } else {
          for (let j = 0; j<capAssessments[i].associated_names.length; j++) {
              if (inRegionOrSearchFarm === capAssessments[i].associated_names[j]) {
                  capAssess.push(capAssessments[i])
              }
          }
      }
    }
    for (let i=0; i<poas.length; i++) {
      if (typeof poas[i].associated_names === "string"){
          if (inRegionOrSearchFarm === poas[i].associated_names) {
              poas2022.push(poas[i])
          }
      } else {
          for (let j = 0; j<poas[i].associated_names.length; j++) {
              if (inRegionOrSearchFarm === poas[i].associated_names[j]) {
                  poas2022.push(poas[i])
              }
          }
      }
    }
   
  let poaStats = {
        gain: 0,
        associated_names: '',
        capacity: 0,
        initial_capacity: 0,
        optimized_capacity: 0,
        revenue_increase: 0,
      }
    
    for (let i=0; i<poas2022.length; i++) {
      poaStats.gain = poaStats.gain+(poas2022[i].Gain_MWhr/poas2022.length)
      poaStats.initial_capacity = poaStats.initial_capacity+(poas2022[i].Initial_AEP_MWhr/poas2022.length/24/365)
      poaStats.optimized_capacity = poaStats.optimized_capacity+(poas2022[i].Optimized_AEP_MWhr/poas2022.length/24/365)
      poaStats.revenue_increase = poaStats.revenue_increase+(poas2022[i].Revenue_Increase/poas2022.length)
      poaStats.associated_names= poas2022[i].associated_names
      poaStats.capacity = poas2022[i].capacity
    }

  
    if (poas2022.length) {
      setVayuPoas([poaStats])
    } else {
      setVayuPoas(false)
    }
    
    setVayuCapAssess(capAssess)

  }, [inRegionOrSearchFarm, capAssessments, poas])


  return (
    <div className={'MapView'}>
    <div className={'MapAndSidebar'}>
      <div className={'Sidebar'}> 
        <div className={'top-200'} style={{margin: 0,padding: '0 8px', border: '1px solid gray'}}>
            <h4 style={{ paddingTop: '1vh', paddingBottom: '1vh', margin: 0, color:'whitesmoke'}}>
              Vayu's Top 200 Opportunities
            </h4>
          {user ? (<ul className={'top-200'} style={{ overflowY:'scroll', border: '1px solid gray', borderRadius: '5px', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #495057, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', listStyle: 'none', width: '100%', margin: 0, padding: 0}}>
                {top200.map((project,idx) => (
                  <li key={idx} style={{padding: 1, height: 'max-content'}}>
                      <button style={{display: 'flex', borderRadius: '5px', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #495057, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', backgroundColor: '#23262A', color: 'whitesmoke', justifyContent:'space-between', textAlign: 'left', padding: 0, height: '100%',width: '100%'}}onClick={(e) => {
                          setVayuCapAssess(false)
                          setTop200Benefits(project)
                          setSelectedFarm(project)
                      }}>
                        <p style={{padding: '2px'}}>
                          {idx+1}. {project.vayu_name}
                          </p>
                          <p style={{padding: '2px'}}>View Details</p>
                          </button>
                      </li>
                  ))}
            </ul>) :  (<><h5>Vayu has conducted Perfomance Optimization Assessments on 2000 US wind farms. Create a VayuAI Data account to view our top 200 opportunities for increasing capacity and capital!</h5></>) }
          
        </div>
      <div className={'regional-farms'} style={{ paddingBottom: '1vh', paddingLeft: '8px', paddingRight: '8px',border: '1px solid gray'}}>
        <h4 style={{ paddingTop: '1vh', paddingBottom: '1vh', margin: 0}}>
          Wind Farms in Region
        </h4>
        
              {(zoom>6) ? (<ul className={'regional-farms'} style={{border: '1px solid gray', borderRadius: '5px', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #495057, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', overflowY:'scroll', ListStyle: 'none', width: '100%', margin: 0, padding:0}}> {projectsInArea.map((project,idx) => (
                <li  key={idx} style={{padding: 1, height: 'max-content'}}>
                    <button style={{textAlign: 'left', backgroundColor: '#23262A',borderRadius: '5px', boxShadow:'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 2px #495057, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)', color: 'whitesmoke', display: 'flex', justifyContent: 'space-between', padding: 0, height: '100%',width: '100%'}}onClick={(e) => {
                        setTop200Benefits(false)
                        setInRegionOrSearchFarm(project.p_name)
                        setSelectedFarm(project.p_name)
                    }}>
                      <p style={{padding: '2px'}}>{project.p_name}</p>
                      <p style={{padding: '2px'}}>View Benefits</p>
                        </button>
                    </li>
                ))} </ul> ) :  <div style={{textAlign: 'center', paddingTop: '2vh'}}>
                  Zoom In On Map To List Wind Farms In Region Displayed
                  </div>}
    
      </div>
      </div>
      <Dropdown className={'Dropdown'} setSelectedFarm={setSelectedFarm} setInRegionOrSearchFarm={setInRegionOrSearchFarm} setTop200Benefits={setTop200Benefits} projectNames={projectNames}></Dropdown>
      <div ref={mapContainer} className={'Map'} style={{ border: '1px solid gray'}}></div>
    </div>
    <div style={{width: '30vw', height: '88vh',  border: '1px solid gray'}}> 
      {(selectedFarmDetails ?
      
      (<div style={{height: '88vh', overflowY:'scroll'}}>
        {user ? (<>  
            {(top200Benefits) ? (<>

              <h2>{top200Benefits.vayu_name}</h2>
              <div style={{backgroundColor: '#212529', margin: '15px', padding: '4px 10px', borderRadius: '10px', boxShadow:'inset 0 -3em 3em #30363C, 0 0 2px #A9B2BA, 0.3em 0.3em 1em #30363C'}}>
              <h4 >Benefits of Vayu Implementation</h4>
              <div style={{fontSize: '.4em'}}>
                <p >This report encompasses data from the following wind farms:</p> <p>{top200Benefits.associated_names}</p>
              </div>
              <p>Nameplate Capacity: {parseFloat(top200Benefits.Nameplate_Capacity).toFixed(1)} MW</p>
              <p>Dependable Capacity: {parseFloat(top200Benefits.Initial_Capacity_MW).toFixed(1)} MW</p>
              <p>Optimized Dependable Capacity {parseFloat(top200Benefits.Optimized_Capacity_MW).toFixed(1)}</p>
              <p>Yearly Power Gain: {parseFloat(top200Benefits.yearly_power_gain_MWhr).toFixed(1)} MWhr</p>
              <p>Optimized Upgraded Dependable Capacity {parseFloat(top200Benefits.optimized_upgrade_capacity).toFixed(1)} MW</p>
              <p>Upgraded Nameplate Capacity: {parseFloat(top200Benefits.upgrade_nameplate_capacity).toFixed(1)} MW</p>
              </div>
             </>) : (
              <>
              <h2>{selectedFarmDetails[0].name}</h2>
             <div style={{backgroundColor: '#212529', margin: '15px', padding: '4px 10px', borderRadius: '10px', boxShadow:'inset 0 -3em 3em #30363C, 0 0 2px #A9B2BA, 0.3em 0.3em 1em #30363C'}}> 
             {vayuPoas && (
       <>
             <h4>Benefits of Vayu Implementation</h4>
             <div style={{fontSize: '.4em'}}>
            
               <p >This report encompasses data from the following wind farms:</p> <p>{vayuPoas[0].associated_names}</p>
             </div>
             <p>Nameplate Capacity: {parseFloat(vayuPoas[0].capacity).toFixed(1)} MW</p>
             <p>Dependable Capacity: {parseFloat(vayuPoas[0].initial_capacity).toFixed(1)} MW</p>
              <p>Optimized Dependable Capacity {parseFloat(vayuPoas[0].optimized_capacity).toFixed(1)}</p>
              <p>Yearly Power Gain: {parseFloat(vayuPoas[0].gain).toFixed(1)} MWhr</p>
              </>)}
       {(!vayuPoas && !vayuCapAssess[0]) && (<p style={{textAlign: 'center', padding: '2vh'}}>Vayu has not performed an analysis of {selectedFarmDetails[0].name}</p>)}
              
            {(vayuCapAssess[0]) && (<>
        
              <p>Optimized Upgraded Dependable Capacity {parseFloat(vayuCapAssess[0].optimized_upgrade_capacity).toFixed(1)} MW</p>
              <p>Upgraded Nameplate Capacity: {parseFloat(vayuCapAssess[0].upgrade_nameplate_capacity).toFixed(1)} MW</p>
              </>
            )}
              </div>
              </>
           )
             } </>) : (<h5> Vayu has conducted Perfomance Optimization Assessments on 2000 US wind farms. Create a VayuAI Data account to view how a VayuAI implementation can add value to {selectedFarmDetails[0].name}!</h5>)}
             
             {(selectedFarmDetails.map((farm, index) => (
              <div key={index} style={{backgroundColor: '#212529', margin: '15px', padding: '4px 10px', borderRadius: '10px', boxShadow:'inset 0 -3em 3em #30363C, 0 0 2px #A9B2BA, 0.3em 0.3em 1em #30363C'}}>
              <h4>{farm.name} Details</h4>
            
              <p>Nameplate Capacity: {(farm.capacity).toFixed(1)} MW</p>
        <p> Total Turbines: {farm.totalTurbines}</p>
        <p>Year Online: {farm.yearOnline}</p>
        <div style={{width: '100%', display: 'flex',textAlign: 'left', justifyContent: 'start'}}><p>Manufacturers: </p> {farm.manufacturers.map((manufacturer, index) => (<p style={{padding: '0px 3px'}} key={index}>{manufacturer}</p>))}
        </div>
        <div style={{width: '100%', display: 'flex', justifyContent: 'start'}}><p style={{textAlign: 'left'}}>Models: </p> {farm.models.map((model, index) => (<p style={{padding: '0px 3px'}}key={index}>{model}</p>))}</div>
        </div>)
             ))}
             
      </div> )
        : (
      <div style={{height: '88vh', overflowY:'scroll'}}>
        <div  style={{backgroundColor: '#212529', margin: '15px', padding: '4px 10px', borderRadius: '10px', boxShadow:'inset 0 -3em 3em #30363C, 0 0 2px #A9B2BA, 0.3em 0.3em 1em #30363C'}}>
            <h4>Welcome to VayuAI's Wind Opportunity Viewer</h4>
            <p>
            VayuAI's Wind Opportunity Viewer is a comprehensive database of wind farms in the United States. Zoom in to display wind turbines in a region or use the search bar to search a wind farm by name. 
              
            
            </p>
            <p>
              VayuAI has conducted Peformance Optimization Assessments (POAs) on 2000 US wind farms. These POAs show the impact of a VayuAI implementation on the wind farm. Sign in or create a VayuAI Data account to view opportunities.
            </p>
            <Authenticator variation={'secondary'}></Authenticator>
            
        </div>
        </div>
       ) )}

     
    </div>
    </div>
  )
}