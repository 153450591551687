// components/Login.js
import { useEffect } from "react";

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';


import { useNavigate, useLocation } from 'react-router';

export function Login() {
  

  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    } else {
      navigate('/login')
    }
  }, [route, navigate, from]);
  return (

      <div style={{width: '30em', paddingTop: '10vh', margin: 'auto'}}>
      <Authenticator variation={'secondary'}></Authenticator>
      </div>

  );
}